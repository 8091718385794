<template>
  <div>
    <TreeMenu
      v-for="(data, index) in treeList"
      ref="tabList"
      :key="data.label_id"
      :index="index"
      :data="data"
      :analysis="analysis"
      :panel-config="panelConfig"
      :depth="0"
      :cur-selected-id="curSelectedId"
      :loaded="loaded"
      :disabled="disabled"
      @get-cur-selected-id="getCurSelectedId"
      @filter-change="filterChange"
      @toggle-child-panel="toggleChildPanel"
    />

    <div
      v-if="showViewMore"
      tabindex="0"
      role="button"
      class="side-filter__item-viewMore"
      :aria-label="language.SHEIN_KEY_PC_15600"
      @keydown.space.stop.prevent="clickViewMore(true)"
      @click="clickViewMore()"
    >
      <span 
        v-if="!loaded" 
        class="add-icon-placeholder"
      ></span>
      <sui_icon_add_12px
        v-else
        style="line-height: 12px"
        size="12px"
      />
      {{ language.SHEIN_KEY_PC_15600 }}
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu'
import { sui_icon_add_12px  } from '@shein-aidc/icon-vue3'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Tree',
  components: {
    TreeMenu,
    sui_icon_add_12px
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      openManually: false, // 主动点击view more展开
      curSelectedId: '',
    }
  },
  computed: {
    panelConfigData() {
      return this.panelConfig?.data || {}
    },
    sliceLen() {
      return +this.panelConfig?.sliceLen || 0
    },
    children() {
      return this.panelConfigData?.children || []
    },
    // 点击的筛选项大于截取的长度或者手动点击了view more
    open() {
      return (
        this.panelConfigData.lastIndex >= this.sliceLen || this.openManually
      )
    },
    // 数据长度大于截取的长度
    exceedSliceLen() {
      return this.sliceLen && this.children.length > this.sliceLen
    },
    showViewMore() {
      return this.exceedSliceLen && !this.open
    },
    treeList() {
      return !this.showViewMore
        ? this.children
        : this.children.slice(0, this.sliceLen)
    },
  },
  watch: {
    'panelConfig.curSelectedId': {
      handler(id) {
        this.getCurSelectedId(id)
      },

      immediate: true,
    },
  },
  methods: {
    // 点击view more
    clickViewMore() {
      this.openManually = true
      this.$nextTick(() => {
        // tab 切换后定位到显示后的第一个
        this.$refs.tabList[this.sliceLen]?.$el.children[0].focus()
      })
    },
    filterChange(payload) {
      this.$emit('filterChange', payload)
    },
    getCurSelectedId(id) {
      this.curSelectedId = id
    },
    toggleChildPanel(payload) {
      this.$emit('toggleChildPanel', payload)
    },
  },
  emits: ['filterChange', 'toggleChildPanel'],
}
</script>

<style lang="less">
.side-filter {
  &__item-viewMore {
    height: 30px;
    line-height: 30px;
    .margin-l(22px);
    font-weight: 700;
    cursor: pointer;
    clear: both;
    .add-icon-placeholder {
      display: inline-block;
      width: 12px;
      height: 13px;
    }
  }
}
</style>
