<template>
  <div>
    <div
      v-if="topTitle"
      class="side-filter__item-title"
    >
      {{ topTitle }}
      <span
        v-if="selectedNum" 
        class="checkbox-filter__reset-icon"
        @click="resetFilter"
      >
        <sui_icon_delete_18px
          size="12px"
          style="line-height: 12px"
        />
      </span>
    </div>
    <div
      v-for="item in dynamicData"
      ref="tabList"
      :key="item.label_id"
      class="side-filter__item-content-each"
      :style="panelConfig.itemStyles"
    >
      <s-checkbox
        v-model="item.isSelected"
        tabindex="0"
        :label="item.label"
        size="size12"
        gap="0"
        :disabled="disabled"
        @change="clickFilterItem(item)"
      >
        {{ item.label }}
        <s-label
          v-if="item.isHot"
          class="color-hot"
          aria-hidden="true"
          type="promo"
        >
          <span class="hot">Hot</span>
        </s-label>
      </s-checkbox>
    </div>
    <div
      v-if="showViewMore"
      tabindex="0"
      role="button"
      class="side-filter__item-viewMore"
      @keydown.space.stop.prevent="clickViewMore(true)"
      @click="clickViewMore()"
    >
      <span
        v-if="!loaded"
        class="add-icon-placeholder"
      ></span>
      <sui_icon_add_12px
        style="line-height: 12px"
        size="12px"
      />
      {{ language.SHEIN_KEY_PC_15600 }}
    </div>
  </div>
</template>

<script>
// import ADA from 'public/src/pages/common/ada'
import { SLabel } from '@shein-aidc/sui-label'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { sui_icon_add_12px, sui_icon_delete_18px  } from '@shein-aidc/icon-vue3'
export default {
  name: 'CheckboxFilterItem',
  components: {
    SLabel,
    SCheckbox,
    sui_icon_add_12px,
    sui_icon_delete_18px,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      attrFilterArr: [], // [87_262]  87父ID，262本身ID
      attrValuesArr: [],
      isClickViewMore: false,
      selectedNum: 0,
    }
  },
  computed: {
    data() {
      return this.panelConfig?.data?.children || []
    },
    sliceLen() {
      return this.panelConfig.sliceLen
    },
    // 点击项大于需要裁减的长度或者点击了view more
    open() {
      return (
        this.panelConfig?.data?.lastIndex >= this.sliceLen ||
        this.isClickViewMore
      )
    },
    dynamicData() {
      return this.showViewMore
        ? this.data.slice(0, this.sliceLen)
        : this.data
    },
    showViewMore() {
      return this.sliceLen && this.data.length > this.sliceLen && !this.open && !this.hasSelectedInHidden
    },
    // 隐藏的部分是否存在选中项
    hasSelectedInHidden() {
      return this.data.slice(this.sliceLen).some(item => item.isSelected)
    },
    topTitle() {
      return this.panelConfig?.topTitle || ''
    },
  },
  watch: {
    panelConfig: {
      handler(config) {
        this.selectedNum = config.selectedNum || 0
      },

      immediate: true,
    },
  },
  methods: {
    // 点击view more
    clickViewMore() {
      this.isClickViewMore = true
      this.$nextTick(() => {
        // tab 切换后定位到显示后的第一个
        this.$refs.tabList[this.sliceLen].children[0].focus()
      })
    },
    // 点击筛选项
    clickFilterItem(item) {
      if (this.disable) return

      if (item.isSelected) {
        this.selectedNum += 1
      } else {
        this.selectedNum -= 1
      }

      // 选中或者反选
      let operationType = ''
      if (item.isSelected) {
        operationType = 'select'
      } else {
        operationType = 'delete'
      }

      this.$emit(
        'filterChange',
        Object.assign(item, { panelConfig: this.panelConfig, operationType })
      )
    },
    resetFilter() {
      this.$emit('resetFilter')
    },
  },
  emits: ['filterChange', 'resetFilter'],
}
</script>

<style lang="less">
.side-filter {
  &__item-title {
    font-weight: bold;
    .icon-lajitong {
      .fr();
      font-weight: normal;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.3);
      &:hover {
        color: #222;
      }
    }
    .checkbox-filter__reset-icon {
      .fr();
      font-weight: normal;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.3);
      &:hover {
        color: #222;
      }
    }
  }
  &__item-content-each {
    .fl();
    min-width: 100%;
    position: relative;
    margin-top: 8px;
    line-height: 18px;
    .sui-checkbox {
      position: relative;
      display: inline-flex;
    }
    .sui-checkbox__label-text {
      word-break: break-all;
      white-space: normal;
      .line-camp(1);
    }
    .color-hot {
      position: absolute;
      width: 26px;
      height: 15px;
      font-size: 12px;
      border-radius: 2px;
      z-index: @zindex-hack;
      text-align: center;
      top: 3px;
      right: -34px;
      .hot {
        position: absolute;
        top: -2px;
        .left(-3px);
        width: 125%;
        height: 125%;
        display: inline-block;
        line-height: 1.5;
        transform: scale(0.8);
        transform-origin: center;
      }
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-checkbox__checked.sui-checkbox__disabled .sui-checkbox__label-select {
      background-color: @sui_color_brand;
      border-color: @sui_color_brand;
      fill: #fff;
    }
    .sui-checkbox__disabled .sui-checkbox__label-select {
      background-color: #fff;
      border-color: #ccc;
      fill: #fff;
    }
    .sui-checkbox__disabled .sui-checkbox__label-text {
      color: #222;
    }
  }
  &__item-content-each_img {
    min-width: auto;
  }
  &__item-viewMore {
    height: 30px;
    line-height: 30px;
    .margin-l(22px);
    font-weight: 700;
    cursor: pointer;
    clear: both;
    .add-icon-placeholder {
      display: inline-block;
      width: 12px;
      height: 13px;
    }
  }
}
</style>
