import { debounce } from 'lodash'

const getElementRect = (wrapper) => {
  if (!wrapper) return {}
  
  const { top = 0, bottom = 0, right = 0, left = 0 } = wrapper.getBoundingClientRect && wrapper.getBoundingClientRect() || {}

  return {
    top, bottom, right, left,
    height: wrapper.offsetHeight || wrapper.innerHeight,
    width: wrapper.offsetWidth || wrapper.innerWidth
  }
}

/**
 * @params
 * @scrollDom 滚动容器，默认window
 * @bottomMask 滚动的视口底部被遮住的高度
 * @exposeRatio 露出多少比例发曝光，默认60%
 */
class ScrollExpose {
  constructor ({ scrollDom, bottomMask = 0, exposeRatio = 0.6 } = {}) {
    this.timer = null
    this.exposeRatio = exposeRatio
    this.scrollEvent = debounce(this.handleExposeEvent, 200).bind(this)
    
    // subscribedMap 记录已经监听曝光的dom
    this.subscribedMap = new Map()
    this.mergeExposeMap = new Map()

    this.exposeSectionSet = new Set()
    
    if (typeof window !== 'undefined') {
      this.scrollContainer = scrollDom || window
      this.scrollClientAreaY = getElementRect(this.scrollContainer).height - bottomMask
      this.init()
    }
  }

  init () {
    this.evt = document.createEvent('HTMLEvents')
    this.evt.initEvent('scroll', false, false)

    this.scrollContainer.addEventListener('scroll', this.scrollEvent, false)
  }

  /**
   * 观察 适用于商品列表
   * @param {Array} section 
   *    @param {String} container 商品列表的容器 类名
   *    @param {String} target  商品本身 类名
   * @param {Function} callback 
   */
  observer ({ section = [] } = {}, callback) {
    if (!section.length) return
    
    
    section.forEach(({ container, target, code }) => {
      const goodsContainer = document.querySelector(container)
      if (!goodsContainer) return
      const goodsTarget = goodsContainer.querySelectorAll(target)
      if (!goodsTarget || !goodsTarget.length) return
      // 保存，用于后续更新
      this.exposeSectionSet.add({ container, target, code, callback })

      this.subscribe({
        dom: goodsTarget, 
        mergeExpose: true,
        callback,
        code,
        exposeType: 'goods',
        container: goodsContainer
      })
    })
  }

  /**
   * 更新观察 适用于商品列表更新 如load more
   */
  updateObserve () {
    [...this.exposeSectionSet].forEach(({ container, target, code, callback }) => {
      const goodsContainer = document.querySelector(container)
      const goodsTarget = goodsContainer.querySelectorAll(target)

      this.subscribe({
        dom: goodsTarget, 
        mergeExpose: true,
        callback,
        code,
        exposeType: 'goods',
        container: goodsContainer
      })
    })
  }

  /**
   * 订阅曝光 适用于单个模块如banner等曝光
   * @param {HTMLDocument} dom 需曝光 DOM
   * @param {Boolean} mergeExpose 是否需要合并曝光 默认false
   * @param {Function} callback 曝光成功回调方法
   * @param {Function} middleJudge 额外的增加判断曝光的中间件, 如果有要返回一个布尔值为是否需要曝光
   */
  subscribe ({ dom, mergeExpose = false, middleJudge = null, callback = null, ...arg } = {}) {
    if (!dom) return
    const doms = dom.length ? [...dom] : [dom]
    if (!doms.length) return

    // 如果没有传入callback 使用promise.then方式调用
    if (typeof callback !== 'function') {
      return new Promise((resolve) => {
        this.initExposeInfo({ doms, mergeExpose, callback: resolve, middleJudge, ...arg })
      })
    } else {
      this.initExposeInfo({ doms, mergeExpose, callback, middleJudge, ...arg })
    }
  }

  /**
   * 初始化曝光的信息
   * @param {*} param0 
   */
  initExposeInfo ({ doms, mergeExpose, callback, middleJudge, ...arg }) {
    let info = {}
    // 1. 合并曝光
    if (mergeExpose) {
      const key = Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36)
      this.mergeExposeMap.set(key, { data: doms, timer: null })
      info = {
        send: false, 
        merge: true, 
        key
      }
    }
    // 2. 单个el初始化曝光信息
    doms.forEach(dom => {
      if (this.subscribedMap.get(dom)) return
  
      this.subscribedMap.set(dom, {
        handle: callback,
        target: dom,
        exposed: false,
        middleJudge,
        ...info,
        ...arg,
      })
    })
    // 3. 触发曝光
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.triggerScroll()
    }, 300)
  }

  /**
   * 曝光
   */
  handleExposeEvent () {
    if (!this.subscribedMap.size) return
    
    this.subscribedMap.forEach(domExposeInfo => {
      const { target, handle, exposed, merge, middleJudge, code = '' } = domExposeInfo
      if (exposed) return
      const withInArea = this.withInExposeArea(target, this.scrollContainer !== window && this.scrollContainer || null)

      if (withInArea) {
        // 如果调用有自定义的判断条件
        if (typeof middleJudge == 'function' && !middleJudge(target)) return
        
        // 标记已经曝光
        domExposeInfo.exposed = true
        this.subscribedMap.set(target, domExposeInfo)
        if (merge) return this.awaitExpose({ handle, domExposeInfo, code })
        // 触发曝光回调
        handle({ container: target, exposeDoms: [target], code })
      }
    })
  }

  /**
   * 合并曝光
   */
  awaitExpose ({ handle, domExposeInfo, code }) {
    const { key } = domExposeInfo
    const info = this.mergeExposeMap.get(key)

    const { data } = info
    clearTimeout(info.timer)
    info.timer = setTimeout(() => {
      const exposeDoms = data.filter(dom => {
        const domInfo = this.subscribedMap.get(dom)
        const { exposed, send } = domInfo
        if (!exposed) return false
        if (send) return false

        this.subscribedMap.set(dom, {
          ...domInfo,
          send: true
        })
        return true
      })
      handle({ container: exposeDoms, exposeDoms, code })
    }, 500)
    this.mergeExposeMap.set(key, info)
  }

  triggerScroll () {
    this.scrollContainer && this.scrollContainer.dispatchEvent(this.evt)
  }

  /**
   * 判断是否在试图内
   * @param {HTMLDocument} dom
   * @param {HTMLDocument} relateTarget 需要对比的窗口dom
   */
  withInExposeArea (dom, relateTarget) {
    // 1. 如果没有要比较的容器 返回
    const relateContainer = relateTarget || this.scrollContainer
    if (!relateContainer) return false

    // 2. 如果自身没有宽高 返回
    const { 
      top: wrapperTop = 0, left: wrapperLeft = 0, 
      right: wrapperRight = 0, bottom: wrapperBottom = 0,
      width: wrapperWidth = 0, height: wrapperHeight = 0 
    } = getElementRect(dom)
    if (wrapperWidth == 0 || wrapperHeight == 0) return false
    
    // 3. 计算临界点比较
    const { top = 0, left = 0, width: scrollWidth = 0, height: scrollHeight = 0 } = getElementRect(relateContainer)
    const relateTop = top
    const relateLeft = left
    const scrollClientAreaX = scrollWidth + relateLeft
    const scrollClientAreaY = relateTarget ? scrollHeight + relateTop : this.scrollClientAreaY

    let bool = false
    let horizontalBool = false

    // 3.1 纵向是否在在试图内
    if (
      (wrapperTop >= 0 && wrapperTop < (scrollClientAreaY - wrapperHeight * this.exposeRatio)) || 
      (wrapperTop <= 0 && wrapperBottom > (relateTop + wrapperHeight * this.exposeRatio))) {
      bool = true
    }

    // 3.2 横向是否在试图内
    if (!(wrapperRight < (relateLeft + wrapperWidth * this.exposeRatio) || wrapperLeft >= (scrollClientAreaX - wrapperWidth * this.exposeRatio))) {
      horizontalBool = true
    }

    return bool && horizontalBool
  }

  /**
   * 重置曝光
   */
  resetExpose (dom) {
    if (!dom) return

    const doms = dom.length ? dom : [dom]

    for (const htmlDom of doms) {
      if (!this.subscribedMap.get(htmlDom)) return

      const domExposeInfo = this.subscribedMap.get(htmlDom)
      domExposeInfo.exposed = false
      this.subscribedMap.set(htmlDom, domExposeInfo)
    }
  }

  /**
   * 移除某个dom的监听
   */
  removeSubscriber (dom) {
    if (!dom) return

    const doms = dom.length ? dom : [dom]

    for (const htmlDom of doms) {
      if (!this.subscribedMap.get(htmlDom)) return
      this.subscribedMap.delete(htmlDom)
    }
  }

  /**
   * 移除滚动事件
   */
  removeExposeEvent () {
    this.scrollContainer.removeEventListener('scroll', this.scrollEvent, false)
  }
}

function getScrollExposer () {
  if (typeof window === 'undefined') return new ScrollExpose()
  if (window.webScrollExpose) return window.webScrollExpose

  const webScrollExpose = window.webScrollExpose = new ScrollExpose()
  return webScrollExpose
}

const webScrollExpose = getScrollExposer()

export {
  webScrollExpose, 
  ScrollExpose
}
