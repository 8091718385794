<template>
  <div
    :style="wrapperStyle"
    class="side-filter__price"
  >
    <div 
      v-show="disabled"
      class="slider-range-mask"
    ></div>
    <div class="slider-range">
      <div class="slider-range__wrapper">
        <div
          id="price-range-slider"
          ref="price-range-slider"
          @keydown.enter.space.prevent="setPrice"
        ></div>
      </div>
      <div class="slider-range__prices">
        <div class="slider-range__prices-lower">
          {{ symbolLeft }}
          <span>{{ curMinPrice }}</span>
          {{ symbolRight }}
        </div>
        <div class="slider-range__prices-upper">
          {{ symbolLeft }}
          <span>{{ curMaxPrice }}</span>
          {{ symbolRight }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

export default {
  name: 'PriceSlider',
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    wrapperStyle: {
      type: Object,
      default: () => ({}),
    },
    commonConfig: {
      type: Object,
      default: () => ({}),
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    adaLevel: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startMinPrice: '', // 初始化最低价格
      startMaxPrice: '', // 初始化最高价格
      curMinPrice: '', // 拖拽后最低价格
      curMaxPrice: '', // 拖拽后最高价格
      filterPrices: {},
    }
  },
  computed: {
    currencyInfo() {
      return this.commonConfig?.currencies?.[this.commonConfig?.lang]?.[this.commonConfig?.currency]
    },
    symbolLeft() {
      return this.currencyInfo?.symbol_left || ''
    },
    symbolRight() {
      return this.currencyInfo?.symbol_right || ''
    },
  },
  watch: {
    panelConfig: {
      handler(panelConfig) {
        this.filterPrices = panelConfig.data
      },

      immediate: true,
    },
  },
  mounted() {
    this.initPriceSlider() // 初始化价格滑块
  },
  methods: {
    initPriceSlider() {
      const { GB_cssRight } = this.commonConfig
      const $slider = this.$refs['price-range-slider'] // slider容器

      this.startMinPrice = this.filterPrices.min_price
      this.startMaxPrice = this.filterPrices.max_price

      this.curMinPrice = this.panelConfig.curMin || this.startMinPrice // 默认取回显的最低价（curMin）
      this.curMaxPrice = this.panelConfig.curMax || this.startMaxPrice // 默认取回显的最高价（curMax）

      if (this.startMinPrice === this.startMaxPrice) {
        return
      }

      noUiSlider.create($slider, {
        start: [this.curMinPrice, this.curMaxPrice],
        behaviour: 'none',
        connect: true,
        direction: GB_cssRight ? 'rtl' : 'ltr',
        step: 1,
        range: {
          min: this.startMinPrice,
          max: this.startMaxPrice,
        },
        cssPrefix: 'aprs-',
        format: wNumb({ decimals: 0 }),
      })

      // 滑动滑块后停止触发的事件
      $slider.noUiSlider.on('end', (values) => {
        this.changePriceRange(values)
      })

      // 滑块滑动过程中触发的事件，values 为两个滑块当前的值，handle 为当前滑动的滑块，0=左滑块，1=右滑块
      $slider.noUiSlider.on('update', (values, handle) => {
        const priceValue = values[handle]

        const key = GB_cssRight 
          ? (handle ? 'curMinPrice' : 'curMaxPrice') 
          : (handle ? 'curMaxPrice' : 'curMinPrice')

        this[key] = priceValue
      })

      this.$nextTick(() => {
        document
          .querySelectorAll('#price-range-slider [tabindex="0"]')
          .forEach((_) => {
            _.tabIndex = this.adaLevel
            if (_.classList.contains('aprs-handle-upper')) {
              _.ariaLabel = this.language.SHEIN_KEY_PC_14521
            } else if (_.classList.contains('aprs-handle-lower')) {
              _.ariaLabel = this.language.SHEIN_KEY_PC_14520
            }
          })
        this.$emit('handleExpose')
      })
    },
    changePriceRange(values) {
      this.$emit('filterChange', {
        panelConfig: this.panelConfig,
        operationType: 'select',
        curMin: +values[0],
        curMax: +values[1],
        startMin: this.startMinPrice,
        startMax: this.startMaxPrice,
        currency: this.currency,
      })
    },
    resetSlider() {
      const { min_price, max_price } = this.filterPrices
      this.$refs['price-range-slider'].noUiSlider.updateOptions({
        start: [min_price, max_price],
        range: {
          min: min_price,
          max: max_price,
        },
      })
    },
    setPrice() {
      this.changePriceRange(this.$refs['price-range-slider'].noUiSlider.get())
    },
  },
  emits: ['filterChange', 'handleExpose'],
}
</script>

<style lang="less">
.side-filter {
  &__price {
    position: relative;
    .slider-range-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: @zindex-hover;
    }
    .icon-lajitong {
      .right(0);
    }
  }
}
.slider-range {
  &__prices {
    .clearfix();
    line-height: normal;
  }

  &__prices-lower {
    float: left /*rtl: ignore*/;
  }

  &__prices-upper {
    float: right /*rtl: ignore*/;
  }

  &__wrapper {
    padding: 15px 0;
    .padding-l(8px);
  }
}
.aprs-target,
.aprs-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: none;
  user-select: none;
  outline: none;
  box-sizing: border-box;
}
.aprs-target {
  height: 2px;
  position: relative;
  background-color: #ccc;
}
.aprs-base,
.aprs-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: @zindex-hack;
}
.aprs-connects {
  overflow: hidden;
  z-index: @zindex-zero;
}
.aprs-connect,
.aprs-origin {
  will-change: transform;
  position: absolute;
  z-index: @zindex-hack;
  top: 0;
  right: 0 /*rtl: ignore*/;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0 /*rtl: ignore*/;
  transform-style: flat;
}
.aprs-connect {
  height: 100%;
  width: 100%;
}
.aprs-origin {
  height: 10%;
  width: 10%;
}
.aprs-connect {
  background-color: @sui_color_main;
}
.aprs-horizontal .aprs-origin {
  height: 0;
}
.aprs-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.aprs-handle {
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}
.aprs-active {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}
[disabled] .aprs-connect {
  background-color: #ccc;
}
[disabled].aprs-target,
[disabled].aprs-handle,
[disabled] .aprs-handle {
  cursor: not-allowed;
}
.aprs-horizontal {
  height: 2px;
}
.aprs-horizontal .aprs-handle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  right: -8px /*rtl: ignore*/;
  top: -8px;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.aprs-tooltip {
  display: block;
  position: absolute;
  color: #000;
  text-align: center;
  white-space: nowrap;
}
.aprs-horizontal {
  .aprs-tooltip {
    transform: translate(-50%, 0) /*rtl: ignore*/;
    .left(50%);
    top: 100%;
  }
}
</style>
