<template>
  <div class="cfli">
    <div
      v-if="isShowTitle"
      class="cfli__title"
    >
      <span>
        {{ panelConfig.label }}
      </span>

      <span
        v-if="showRightIcon"
        class="cfli__icon"
        @click="setClickViewMore(false)"
      >
        <sui_icon_min_16px
          size="16px"
          style="line-height: 16px"
        />
      </span>
    </div>
    <div v-if="panelConfig.type == 'symbol'">
      <SymbolItem
        :panel-config="{data: panelConfig}"
        :lazy-img="lazyImg"
        :transform-img="transformImg"
        :disabled="disabled"
        @filter-change="filterChange"
      />
    </div>
    <div
      v-else
      class="cfli__content"
    >
      <div
        v-for="item in dynamicList"
        ref="tabList"
        :key="item.label_id"
        class="cfli__item"
        :style="panelConfig.itemStyles"
      >
        <s-checkbox
          v-model="item.isSelected"
          tabindex="0"
          :label="item.label"
          size="size12"
          gap="0"
          :disabled="disabled"
          @change="clickFilterItem(item)"
        >
          {{ item.label }}
          <s-label
            v-if="item.isHot"
            class="color-hot"
            aria-hidden="true"
            type="promo"
          >
            <span class="hot">Hot</span>
          </s-label>
        </s-checkbox>
      </div>

      <div
        v-if="showViewMore"
        class="cfli__viewMore"
        @click="setClickViewMore"
      >
        <span @click="onUnfold">
          <sui_icon_more_down_18px />
          {{ language.SHEIN_KEY_PC_28056 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import ADA from 'public/src/pages/common/ada'
import { SLabel } from '@shein-aidc/sui-label'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import {
  sui_icon_min_16px,
  sui_icon_more_down_18px
} from '@shein-aidc/icon-vue3'
import SymbolItem from './SymbolItem'

export default {
  name: 'CheckboxFilterListItem',
  components: {
    SLabel,
    SCheckbox,
    SymbolItem,
    sui_icon_min_16px,
    sui_icon_more_down_18px
  },
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    loaded: {
      type: Boolean,
      default: false
    },
    panelConfig: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isShowTitle: {
      type: Boolean,
      default: true
    },
    // 懒加载占位图
    lazyImg: {
      type: String,
      default: '',
    },
    // 处理图片
    transformImg: {
      type: [String, Function],
      default: '',
    },
  },
  data() {
    return {
      isClickViewMore: false
    }
  },
  computed: {
    allList() {
      return this.panelConfig?.children || []
    },
    dynamicList() {
      return this.showViewMore ? this.allList.slice(0, 3) : this.allList
    },
    showViewMore() {
      return this.allList.length > 3 && !this.isClickViewMore
    },
    showRightIcon() {
      return !this.showViewMore && this.allList.length > 3
    }
  },
  methods: {
    setClickViewMore(flag = true) {
      this.isClickViewMore = flag
    },
    // 点击筛选项
    clickFilterItem(item) {
      if (this.disable) return

      // 选中或者反选
      let operationType = ''
      if (item.isSelected) {
        operationType = 'select'
      } else {
        operationType = 'delete'
      }

      this.setClickViewMore(true)

      this.$emit(
        'filterChange',
        Object.assign(item, { panelConfig: this.panelConfig, operationType })
      )
    },
    filterChange (params) { 
      this.$emit('filterChange', Object.assign(params, { panelConfig: this.panelConfig }))
    },
    // 展开
    onUnfold() {},
    // 收起
    onPackUp() {}
  },
  emits: ['filterChange']
}
</script>

<style scoped lang="less">
.cfli {
  &__title {
    color: #000;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__icon {
    color: #000;
    cursor: pointer;
    float: right;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  
  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 10px;
    min-width: fit-content;
    
    /deep/ .sui-checkbox {
      display: inline-flex;
    }

    /deep/ .sui-checkbox__label-text {
      word-break: break-all;
      white-space: normal;
      .line-camp(1);
    }

    > label {
      flex: 0 0 50%;
      max-width: 100%;
    }

    /deep/ .sui-checkbox__disabled {
      .sui-checkbox__label-select {
        background-color: #fff;
        border-color: #ccc;
      }

      .sui-checkbox__label-text {
        color: #222;
      }
    }

    /deep/ .sui-checkbox__checked {
      .sui-checkbox__label-select {
        background-color: #000;
        border-color: #000;
      }
    }
  }

  &__viewMore {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 10px;
    cursor: pointer;
    color: #000;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
